import type { OptionsObject } from 'notistack'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ApplicationException, ErrorInfo } from '../../components/common/ApplicationException'
import { getCurrentPage } from '../../utils'
import { ThunkApi } from '../../store'
import { AlarmSeverityLevels } from 'common/api/v1/types'

export type NotificationType = 'newVersionAvailableSnackbar' | 'linkSnackbar' | 'fatalError' | 'basic'

export type NotificationErrorData = Pick<ErrorInfo, 'text' | 'details'> & {
  httpStatusCode: number | undefined
}

export interface Notification {
  options: OptionsObject
  type: NotificationType
  dismissed?: boolean
  message?: string
  data?: NotificationErrorData | string
}

export const enqueueSnackbar = createAction<Notification>('notification/enqueueSnackbar')

export const enqueueErrorSnackbar = createAsyncThunk<
  void,
  { error: ApplicationException; operation?: string },
  ThunkApi
>('notification/enqueueErrorSnackbar', async ({ error, operation }, { dispatch }) => {
  const { errorInfo } = error
  // eslint-disable-next-line no-console
  console.error('error', JSON.stringify(errorInfo, null, 3))

  const data: NotificationErrorData = {
    text: (operation && formatOperationFailedTitle(operation, error)) || errorInfo?.text || '',
    details: errorInfo?.details || '',
    httpStatusCode: errorInfo?.origin?.status || undefined,
  }

  dispatch(
    enqueueSnackbar({
      message: errorInfo.text,
      type: 'fatalError',
      data,
      options: {
        variant: 'error',
        persist: true,
      },
    }),
  )
})

export function formatOperationFailedTitle(operation: string, error: ApplicationException) {
  const verb = error?.errorInfo.errorCode?.startsWith('4') ? 'Unable' : 'Failed'
  return `${verb} to ${operation}`
}

export const enqueueSuccessSnackbar = createAsyncThunk<void, string, ThunkApi>(
  'notification/enqueueSuccessSnackbar',
  async (message, { dispatch }) => {
    dispatch(
      enqueueSnackbar({
        message,
        type: 'basic',
        options: { variant: 'success' },
      }),
    )
  },
)

export const enqueueAlarmSnackbar = createAsyncThunk<
  void,
  {
    message: string
    severity?: AlarmSeverityLevels
  },
  ThunkApi
>('notification/enqueueAlarmSnackbar', async ({ message, severity }, { dispatch, extra: { routes } }) => {
  if (getCurrentPage() !== routes.stream.route) {
    dispatch(
      enqueueSnackbar({
        message,
        type: 'linkSnackbar',
        data: routes.alarms({ alarmTab: severity || undefined }),
        options: { variant: 'warning', anchorOrigin: { horizontal: 'right', vertical: 'top' } },
      }),
    )
  }
})

export const enqueueNewVersionAvailableSnackbar = createAsyncThunk<void, void, ThunkApi>(
  'notification/enqueueNewVersionAvailableSnackbar',
  async (_, { dispatch }) => {
    dispatch(
      enqueueSnackbar({
        type: 'newVersionAvailableSnackbar',
        options: {
          variant: 'info',
          persist: true,
        },
      }),
    )
  },
)

export const closeSnackbar = createAction<OptionsObject['key']>('notification/closeSnackbar')
export const removeSnackbar = createAction<OptionsObject['key']>('notification/removeSnackbar')
export const closeAllSnackbars = createAction<void>('notification/closeAllSnackbars')
